<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalData.idcard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" size="small" placeholder="请输入手机号" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="goAddEdit()">选择学员</el-button>
            <el-button type="primary" v-if="!lastPageData.activityId" class="bgc-bv" round @click="chooseFromOrganization">批量导入</el-button>
            <el-button type="primary" class="bgc-bv" round @click="$router.back()">返回</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" minWidth="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="200" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="120" />
              <el-table-column label="准考证号" align="left" show-overflow-tooltip prop="userCandidateNo" minWidth="200" />
              <!-- <el-table-column label="字典" align="left" show-overflow-tooltip prop="examType" minWidth="120">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column label="字段" align="left" show-overflow-tooltip min-width="300px">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column> -->
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="deleteStudents(scope.row)">删除</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="toResetYourPassword(scope.row)">重置密码</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
      <!--鉴定机构通用导入-->
      <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
      <!-- importType  导入类型 学员-->
      <!-- unit  单位 学员-人-->
      <!--fileType  EXCEL  ZIP-->
      <accreditationAgencyImport
              ref="accreditationAgencyImport"
              @eventBus="accreditationAgencyImportBack"
              businessType="EXAMINATION_USER_IMPORT"
              importType="学员"
              unit="人"
              fileType="EXCEL"
      />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";

// 鉴定机构通用导入
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
export default {
  name: "examination_onlineTest_studentList",
  components: {
    accreditationAgencyImport,

    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        examinationId: "", // 考试id
        activityId: "", // 考务id
      },
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        idcard: "", // 身份证号
        mobile: "", // 手机号
      },
    };
  },
  created() {
    this.lastPageData.examinationId = this.$route.query?.examinationId ?? "";
    this.lastPageData.activityId = this.$route.query?.activityId ?? "";
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
      chooseFromOrganization(){
          // console.log('examinationId',this.lastPageData.examinationId)
          this.$refs.accreditationAgencyImport.showPopup(this.lastPageData.examinationId)
      },
      accreditationAgencyImportBack(){
          this.getData(-1)
      },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试科目
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.$route.query?.examinationId
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.idcard) {
        params.idcard = this.retrievalData.idcard;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      this.doFetch({
        url: "/biz/examination/user/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 跳转 - 选择学员
    goAddEdit() {
      this.$router.push({
        path: "/web/examination/onlineTest/studentsChoose",
        query: {
          examinationId: this.lastPageData.examinationId,
          activityId: this.lastPageData.activityId
        }
      });
    },
    // 操作 - 删除
    deleteStudents(row) {
      this.$confirm('您确定要删除该学员吗？', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'bgc-bv',
        cancelButtonText: '取消',
      }).then(() => {
        this.$post("/biz/examination/user/deleteUser", { examinationUserIds: [row.userId], examinationId:this.lastPageData.examinationId }, 3000, true, 6)
          .then((res) => {
            this.getData(this.pageNum)
          })
          .catch((err) => {
            return;
          });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    // 操作 - 重置密码
    toResetYourPassword(row) {
      this.$confirm('是否将学员账号的密码重置？重置后，变为初始密码Aa+身份证号后六位', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'bgc-bv',
        cancelButtonText: '取消',
      }).then(() => {
        this.$post("/biz/examination/user/resetPassword", { userId: row.userId }, 3000, true, 6)
          .then((res) => {
            this.getData(this.pageNum)
            this.$message({
              message: "操作成功",
              type: "success",
            });
          })
          .catch((err) => {
            return
          });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
  